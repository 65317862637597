<template>
  <div>
    <el-card style="margin-bottom: 10px">
      <nav>
        <div class="back" @click="back">
          <i class="el-icon-arrow-left" />返回
        </div>
      </nav>
    </el-card>
    <el-card>
      <el-form
        ref="form"
        :model="form"
        :rules="addFormRulue"
        label-width="110px"
      >
        <el-form-item label="姓名" prop="realName" >
          <el-col :span="4">
            <el-input
              v-model="form.realName"
              :maxlength="100"
              show-word-limit
            />
          </el-col>
        </el-form-item>
        <el-form-item label="级别" prop="jobLevel">
          <el-col :span="4">
            <el-select style="width: 100%" v-model="form.jobLevel">
              <el-option
                  v-for="(item, index) in config && config.userJobLevel"
                  :key="index"
                  :label="item"
                  :value="index * 1"
              />
            </el-select>
          </el-col>

        </el-form-item>
        <el-form-item label="职位" prop="job">
          <el-col :span="4">
            <el-input v-model="form.job" />
          </el-col>
        </el-form-item>
        <el-form-item label="身份" prop="realName">
          <el-col :span="4">
            <el-select style="width: 100%"
                       v-model="form.category" placeholder="选择身份" size="medium"
                       multiple
            >
              <el-option label="演讲嘉宾" value="2" />
              <el-option label="企业高管" value="1" />
            </el-select>
          </el-col>

        </el-form-item>
        <!-- <el-form-item label="简介" prop="desc">
          <el-col :span="11">
            <el-input
              v-model="form.desc"
              :maxlength="300"
              show-word-limit
            ></el-input>
          </el-col>
        </el-form-item> -->



        <el-form-item label="手机号">
          <el-col :span="4">
            <el-input v-model="form.phone" @change="" />
          </el-col>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-col :span="4">
            <el-input v-model="form.email" @change="" />
          </el-col>
        </el-form-item>
        <el-form-item label="企业邮箱" prop="businessEmail">
          <el-col :span="4">
            <el-input v-model="form.businessEmail" @change="" />
          </el-col>
        </el-form-item>
        <el-form-item label="微信" prop="wechat">
          <el-col :span="4">
            <el-input v-model="form.wechat" />
          </el-col>
        </el-form-item>


        <el-form-item label="公司" prop="company">
          <el-col :span="8" style="display: flex">
            <el-input style="width: 50%;margin-right: 20px"  v-model="form.company" :disabled="isDisabled"></el-input>
            <el-button type="primary" v-if="isBind" @click="handleBind(true)">绑定企业</el-button>
<!--            <el-button type="primary" v-else @click="handleBind(false)">解绑企业</el-button>-->


          </el-col>
        </el-form-item>
        <el-form-item label="行业类型" prop="type">
          <el-checkbox-group
            v-model="form.type"
            :max="3"
            @change="
              form.business = []
              form.subBusiness = []
            "
          >
            <el-checkbox
              v-for="(item, index) in typeList"
              :key="item.id"
              :label="item.id + ''"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="一级行业" prop="business">
          <el-checkbox-group
            v-model="form.business"
            v-if="form.business.length > 3"
            @change="form.subBusiness = []"
          >
            <el-checkbox
              v-for="(item, index) in businessList"
              :key="item.id"
              :label="item.id + ''"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
          <el-checkbox-group
            v-model="form.business"
            :max="3"
            @change="form.subBusiness = []"
            v-else
          >
            <el-checkbox
              v-for="(item, index) in businessList"
              :key="item.id"
              :label="item.id + ''"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="二级行业" prop="subBusiness">
          <el-checkbox-group  v-model="form.subBusiness" :max="3">
            <el-checkbox
              v-for="(item, index) in subBusinessList"
              :key="item.id"
              :label="item.id + ''"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>

        <el-form-item label="城市" prop="city">
          <el-select
            v-model="form.province"
            @change="citys(form.province, true)"
          >
            <el-option
              v-for="(item, index) in province_arr"
              :key="item.code"
              :label="item.name"
              :value="item.code + ''"
            />
          </el-select>
          <el-select v-model="form.city" class="ml">
            <el-option
              v-for="(item, index) in city_arr"
              :key="item.code"
              :label="item.name"
              :value="item.code + ''"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="公司地址">
          <el-col :span="8">
            <el-input style="width: 89%" v-model="form.address" @change="" />
          </el-col>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-col :span="8">
            <el-input style="width: 89%" v-model="form.remark" @change="" />
          </el-col>
        </el-form-item>
        <el-form-item label="小助手" prop="waId">
          <el-col :span="8">
            <el-select style="width: 89%" v-model="form.waId" multiple :multiple-limit="3">
              <el-option
                  v-for="(item, index) in $store.state.NormalList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id + ''"
              />
            </el-select>
          </el-col>

        </el-form-item>
        <el-form-item label="小助手备注" prop="crowd">
          <el-col :span="8">
            <el-input style="width: 89%" v-model="form.crowd" >

            </el-input>
          </el-col>


        </el-form-item>

        <el-form-item label="活动" prop="activityId">
         <el-col :span="8">
           <el-select

               v-model="form.activityId"
               multiple
               :multiple-limit="3"
               filterable
               remote
               reserve-keyword
               placeholder="请输入活动名称模糊查询"
               :remote-method="remoteMethod"
               :loading="loading"
               style="width:89%;"
           >
             <el-option
                 v-for="item in options"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value"
             />
           </el-select>
         </el-col>
        </el-form-item>
        <el-form-item label="个人简介" prop="remark">
          <el-col :span="8">
            <el-input type="textarea" style="width: 89%" v-model="form.desc" @change="" />
          </el-col>
        </el-form-item>
        <el-form-item label="嘉宾宣传照" prop="avatar">
          <el-upload
              class="avatar-uploader"
              :action="this.$store.getters.imgUrl"
              accept=".jpg,.jpeg,.png,.gif,.ico,svg"
              :headers="header"
              :show-file-list="false"
              :on-success="handleAvatarSuccess_1"
              :data="action_1"
          >
            <img
                v-if="form.avatar"
                :src="baseurl + yu + form.avatar"
                class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="资料打包">
          <up-file
              :value.sync="form.document"
              tip="请上传zip，rar文件（包含宣传物料，logo源文件等内容"
          ></up-file>
        </el-form-item>
        <!-- <el-form-item label="嘉宾宣传照" prop="avatar">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            accept=".jpg,.jpeg,.png,.gif,.ico,svg"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess_1"
            :data="action_1"
          >
            <img
              v-if="form.avatar"
              :src="baseurl + yu + form.avatar"
              class="avatar"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <div class="el-upload__tip waring" slot="tip">
              大小不可超过2M,只允许上传jpg,jpeg,png,gif,ico,svg格式的图片
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="资料打包">
          <el-upload
            ref="pdf"
            class="upload-demo"
            :on-progress="beforeAvatarUpload"
            :on-remove="word"
            :action="this.$store.getters.imgUrl"
            accept=".zip , .rar"
            :headers="header"
            :on-success="handleAvatarSuccess"
            :data="action"
            :on-preview="load"
            :limit="1"
            :show-file-list="true"
          >
            <el-button size="small" type="primary" v-if="pdfstaus"
              >点击上传</el-button
            >
            <button
              size="small"
              type="primary"
              :disabled="true"
              v-else
              class="lod"
            >
              加载中
            </button>
            <div class="el-upload__tip waring" slot="tip">
              请上传zip，rar文件（包含宣传物料，logo源文件等内容
            </div>
          </el-upload>
        </el-form-item> -->

        <el-form-item>
          <el-button v-if="!id" type="primary" @click="add">{{
            btn ? '确 定' : 'loading..'
          }}</el-button>
          <el-button v-if="id" type="primary" @click="editadd">{{
            btn ? '修 改' : 'loading..'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-dialog title="绑定企业" :visible.sync="show" width="35%">
      <el-form ref="formM" label-width="120px" >
        <el-form-item label="输入企业名" >
                      <div-autocomplete
                                        :selectId.sync="state.enterpriseId"
                                        :value.sync="state.company"
                      ></div-autocomplete>
        </el-form-item>
      </el-form>

      <span slot="footer">
                <el-button @click="show=false">取消</el-button>
                <el-button type="primary" @click="handleSubmit">确认</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      state: {
        enterpriseId:'',
        company:''
      },
      isBind:true,
      isDisabled:false,
      show:false,
      keysome: '',
      sd: [],
      loading: false,
      options: [],
      // 提交数据
      form: {
        category:[],
        realName: '',
        // desc: '',
        company: '',
        job: '',
        phone: '',
        email: '',
        businessEmail: '',
        wechat: '',
        jobLevel: '',
        city: '',
        province: '',
        address: '',
        remark: '',
        service: '',
        activityId: [],
        // avatar: '',
        type: [],
        business: [],
        subBusiness: [],
        document: '',
        enterpriseId: '',
        desc:'',
      //   小助手备注
        crowd:""
        
      },
      ys: false,
      pdfstaus: true,
      typeList: [],
      businessList: [],
      subBusinessList: [],
      // addFormRulue: {
      //   realName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
      //   company: [{ required: true, message: '请输入公司', trigger: 'blur' }],
      //   phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
      //   email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
      //   job: [{ required: true, message: '请输入职位', trigger: 'blur' }],
      //   type: [{ required: true, message: '请输入类型', trigger: 'blur' }],
      //   jobLevel: [{ required: true, message: '请输入级别', trigger: 'blur' }],
      //   business: [{ required: true, message: '请输入行业', trigger: 'blur' }],
      //   city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
      //   address: [
      //     { required: true, message: '请输入公司地址', trigger: 'blur' }
      //   ],
      //   year: [{ required: true, message: '请输入年份', trigger: 'blur' }]
      // },
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      btn: true,
      action: {
        action: 'speaker',
        type: 'document'
      },
      action_1: {
        action: 'speaker'
      },
      area: [],
      header: {
        Token: sessionStorage.getItem('token')
      },
      province_arr: [],
      id: null,
      city_arr: []
    }
  },

  created() {
    // this.getea()
    this.getfrom()
    this.getp()

  },
  mounted() {
    // console.log(this.area);
    console.log(1)

    if (document.querySelector('.v-modal')) {
      document.querySelector('.v-modal').click()
      this.ys = true
    }
    sessionStorage.setItem('action', 'report')
  },
  watch: {
    'form.type'(newValue, oldValue) {
      if(this.form.type.length){
        this.getType('1')
      }
    },
    'form.business'(newValue, oldValue) {
      if(this.form.business.length){
        this.getType('2')
      }
    },
    'form.enterpriseId'(newValue,oldValue){
      if(newValue){
        this.isDisabled=true
      }else {
        this.isDisabled=false
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1) // 返回上一层
    },
    reset(){
      this.state={
        enterpriseId:'',
        company:''
      }
    },
    async getEnterprise(id){
      this.$http.get("/admin/Enterprise/getById?id=" + id).then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.form.business= res.data.business.split(",").filter(Boolean),
           this.form.type= res.data.type.toString().split(","),
           this.form.subBusiness=res.data.subBusiness.split(",").filter(Boolean)
        }
      });
    },
    handleSubmit(){
      if(this.form.enterpriseId){
        this.form.enterpriseId= this.state.enterpriseId
        this.form.company=this.state.company
        this.show=false
        this.getEnterprise(this.form.enterpriseId)
        // this.$alert('绑定企业，企业类型/行业将同步该绑定企业的', '提示', {
        //   confirmButtonText: '确定',
        //   cancelButtonText: '取消',
        // })
        this.reset()
      }else {
        this.$message('请选择企业后再点击确认！')
      }
    },
    handleBind(type){
      console.log(this.form.enterpriseId)
      if(type){
        this.state.company=''
        this.show=true
      }else {
        this.$confirm('此操作解除绑定企业, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.form.enterpriseId=''

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });


      }


},
    word() {
      this.pdfstaus = true
    },
    beforeAvatarUpload() {
      this.pdfstaus = false
    },
    remoteMethod(qu) {
      if (qu != '') {
        this.loading = true
        this.$http
          .get('/admin/Activity/getList', {
            params: {
              page: 1,
              pageSize: 50,
              title: qu,
              eventDate: '',
              publishDate: '',
              publishStatus: '',
              checkStatus: '',
              acId: '',
              order: ''
            }
          })
          .then(({ data: res }) => {
            this.loading = false
            if (res.errorCode == 200) {
              console.log(res.data)
              this.options = []
              res.data.list.forEach(item => {
                this.options.push({ value: item.id, label: item.title })
              })
            }
          })
      }
    },
    getfrom() {
      if (this.$route.query.id) {
        var id = this.$route.query.id
        this.id = id
        this.$http
          .get('/admin/UserGuest/getById?id=' + id)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              // console.log(res);
              this.form = {
                ...res.data,
                category:res.data.category?res.data.category.toString('').split(','):[],
                activityId:
                  !!res.data.activityId && res.data.activityId.split(','),
                waId: !!res.data.waId && res.data.waId.split(','),
                business:res.data.business? !!res.data.business && res.data.business.split(','):[],
                type: res.data.type?  res.data.type.toString().split(','):[],
                subBusiness: res.data.subBusiness? !!res.data.subBusiness && res.data.subBusiness.toString().split(','):[],
              }
              this.isDisabled=this.form.enterpriseId?true:false
              this.isBind=this.form.enterpriseId?false:true
              var eds = res.data.activityId.split(',')
              var act =
                res.data.activityName && res.data.activityName.split(',')
              console.log(act)
              eds.forEach((item, n) => {
                this.options.push({ value: item, label: act && act[n] })
              })
              console.log(this.options)
              // console.log(this.form)
              this.citys(this.form.province)
              this.form.id = id * 1
            }
          })
      }
      this.getType()
    },
    async getType(val) {
      if (val == 1) {
        var { data: res } = await this.$http.get(
          `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form.type.toString()}&businessId=`
        )
        if (!this.form.type.length) {
          this.businessList = []
          return
        }
        this.businessList = res.data.list
        return
      }
      if (val == 2) {
        var { data: res } = await this.$http.get(
          `/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=${this.form.type.toString()}&businessId=${this.form.business.toString()}`
        )
        if (!this.form.business.length) {
          this.subBusinessList = []
          return
        }
        this.subBusinessList = res.data.list
        return
      }
      var { data: res } = await this.$http.get(
        '/admin/EnterpriseCategory/getList?keyword=&page=1&pageSize=100&order=&typeId=0&businessId='
      )
      this.typeList = res.data.list
    },
    load() {
      // console.log(file);
      location.href = this.baseurl + this.yu + this.form.document
    },
    // 确定
    add() {
      this.$refs.form.validate(() => {
        if (this.btn) {
          this.$http
            .post('/admin/UserGuest/add', {
              ...this.form,
              activityId:
                this.form.activityId && this.form.activityId.join(','),
              waId: this.form.waId && this.form.waId.join(','),
              type: this.form.type.toString(),
              business: this.form.business && this.form.business.join(','),
              subBusiness: this.form.subBusiness.toString(),
            })
            .then(({ data: res }) => {
              if (res.errorCode == 200) {
                this.$message.success('操作成功')
                this.$router.push('/user_guest')
              }
              this.btn = true
              // if(res.)
            })
        }
        this.btn = false
      })

      // })
    },
    // 修改
    editadd() {
      if(this.form){

      }
      this.$refs.form.validate(() => {
        if (this.btn) {

          this.$http
              .post('/admin/UserGuest/edit', {
                ...this.form,
                category:this.form.category.join(','),
                activityId:
                    this.form.activityId && this.form.activityId.join(','),
                waId: this.form.waId && this.form.waId.join(','),
                type: this.form.type.toString(),
                business: this.form.business && this.form.business.join(','),
                subBusiness: this.form.subBusiness.toString(),
              })
              .then(({ data: res }) => {
                if (res.errorCode == 200) {
                  this.$message.success(res.message)
                  this.$router.push('/user_guest')
                  if (this.ys) {
                    this.ys = false
                    sessionStorage.setItem('adf', 'true')
                    this.$store.state.Did = this.form.id
                  } else {
                    this.$store.state.counts = 0
                  }
                }
                this.btn = true
              })
          this.btn = false
          }

      })
    },
    handleAvatarSuccess_1(res) {
      if (res.errorCode == 200) {
        this.form.avatar = res.data.url
        this.$forceUpdate()
      }
    },
    handleAvatarSuccess(res, file) {
      this.form.document = ''
      if (res.errorCode == 200) {
        this.form.document = res.data.url
        this.$forceUpdate()
        // this.form=[...this.form]
      } else {
        this.$refs.pdf.clearFiles()
      }
      this.pdfstaus = true
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeUpload() {
      if (this.pdfstaus) {
        return false
      }
    },
    getea() {
      this.$http.get('/admin/ReportCategory/getList').then(({ data: res }) => {
        if (res.errorCode == 200) {
          this.area = res.data
          console.log(this.area)
        }
      })
    },
    async getp() {
      const jj = await this.$store.dispatch('getProvince')
      this.province_arr = jj.data
    },
    async citys(val, key) {
      if (key) {
        this.form.city = ''
      }
      // console.log(val)
      const jj = await this.$store.dispatch('getCity', val)
      this.city_arr = jj.data
    }
  },
  computed: {
    ...mapState(['config'])
    // citys() {
    //   return async function(val) {
    //     const jj = await this.$store.dispatch('getCity', val)
    //     return jj.data
    //   }
    // }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  height: 178px;
  display: block;
}
.waring {
  color: #fda400;
  line-height: 24px;
}
.ml {
  margin-left: 10px;
}
.lod {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: not-allowed;
  background-color: #fda400;
  // background: #fff;
  border: 1px solid #dcdfe6;
  color: white;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  transition: 0.1s;
  font-weight: 500;
  padding: 8px 15px;
  font-size: 13px;
  border-radius: 4px;
}
</style>
